.calenderword {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    padding: 50px 20px 50px;
}

.calendar-header {
    width: 14.28%;
}

.calendersecondsec {
    background-color: #ffbaba;
    height: 100vh;
    height: 100%
}

.manageinputwidth {
    padding: 0 60px;
}

.manageinputwidth .form-control {
    background-color: transparent;
    width: 100%;
    color: black;
    background-color: transparent !important;
    border: 0;
    outline: 0;
    box-shadow: 0 !important;
    font-size: 23px;
    text-align: center;
}

.manageinputwidth .form-control:focus {
    color: black;
    background-color: transparent !important;
    border: 0;
    outline: 0;
    box-shadow: none !important;
    font-size: 23px;
}

.iconcolorset {
    color: #ffe2e2;
    cursor: pointer;
}

.iconcolorsetmobil {
    color: #ffe2e2;
    cursor: pointer;
}

.iconcolorsetanother {
    color: #ffe2e2;
    cursor: pointer;
}

.calandermonthscroll {
    max-height: 213px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.monthcolorset {
    padding: 10px 0;
    color: #888;
    cursor: pointer;
    font-size: 23px;
    font-weight: 500;
    text-align: center;
}

.active-month {
    font-weight: bold;
    color: #000;
}

.monthcolorset {
    width: 100%;
    text-align: center;
}

.calandermonthscroll::-webkit-scrollbar {
    display: none;
}

.calenderthirdsection {
    background-color: #ffeded;
    height: 100vh;
    height: 100%
}

.datessetfont {
    font-size: 25px;
    font-weight: 700;
}

.setmenubar {
    padding: 50px 40px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerline {
    font-size: 18px;
    color: black;
    padding: 5px 40px 10px;
}

.menubariconcolorchange {
    color: #ff5455;
    cursor: pointer;
}

.menubariconspace {
    gap: 10px
}

.calendar-table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border: none;
    font-size: 18px;
}

.calendar-table th {
    border: none;
    padding: 10px 10px 35px 10px;
    color: #ff5455;
    background-color: transparent;
    cursor: pointer;
}

.calendar-table td {
    border: none;
    padding: 10px 10px 20px 10px;
    color: #ff5455;
    background-color: transparent;
    cursor: pointer;
    width: 14.28%
}

.sidebardetails {
    font-size: 16px;
    color: #9f9f9f;
    padding: 7px 0;
    font-weight: 700;
}

.managespacedetails {
    padding: 0 40px;
    font-weight: 200;
    overflow: auto;
    min-height: 50px;
    max-height: 280px;
    scrollbar-width: thin;
    scrollbar-color: #ff5455 transparent;
}

.managespacedetails::-webkit-scrollbar {
    width: 5px;
}

.managespacedetails::-webkit-scrollbar-thumb {
    background-color: #ff5455;
    border-radius: 10px;
}

.managespacedetails::-webkit-scrollbar-track {
    background-color: transparent;
}

.currentDatecoloure {
    background-color: #A5B9FF !important;
    border-radius: 50px;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectedDatecoloure {
    background-color: #3ebaff !important;
    border-radius: 50px;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lightcolourset-unselect {
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lightcolourset-select {
    background-color: #78c9fa !important;
    border-radius: 50px;
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lightcolourset-currentDate {
    background-color: #C6CDFA !important;
    border-radius: 50px;
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 400px) {
    .calendar-table th {
        border: none;
        padding: 0;
        color: #ff5455;
        background-color: transparent;
        cursor: pointer;
        padding-bottom: 15px;
    }

    .calendar-table td {
        border: none;
        padding: 0;
        color: #ff5455;
        background-color: transparent;
        cursor: pointer;
        padding-bottom: 15px;
    }

    .calendar-table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border: none;
    }
}

@media (max-width: 1199px) and (min-width: 768px) {
    .setmenubar {
        padding: 50px 20px 0px;
    }

    .headerline {
        font-size: 18px;
        color: black;
        padding: 5px 20px 10px;
    }
}

@media (max-width: 767px) {
    .setmenubar {
        padding: 25px 15px 0px;
    }

    .headerline {
        font-size: 18px;
        color: black;
        padding: 5px 15px 20px;
    }

    .calenderthirdsection {
        height: 100%;
    }

    .managespacedetails {
        padding: 0px 20px 5px;
    }

    .setholidayscroll {
        padding: 0px 20px 5px !important;

    }

    .phaseofmoon {
        margin: 5px 20px 5px !important;
    }

    .calendersecondsec {
        height: 100%;
    }

    .calenderword {
        padding: 10px 0 0px;
        text-align: center;
    }

    .calandermonthscroll {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        height: auto;
        scroll-snap-type: x mandatory;
    }

    .monthcolorset {
        flex: 0 0 auto;
        width: 100%;
        /* Ensures each month takes up the full width */
        text-align: center;
        scroll-snap-align: center;
        transition: transform 0.3s ease-in-out;
        padding: 0;

    }

    .setyearmonth {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .setarrowres {
        display: flex;
        width: 50%;
        align-items: center;
        padding: 0 20px
    }

    .manageinputwidth {
        padding: 0;
    }

    .setyearresponsive {
        width: 50%;
        padding: 0 20px
    }

    .iconcolorset {
        width: 100%;
    }

    .manageinputwidth .form-control {
        padding: 0;
    }
}

.setselectmenu {
    gap: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.setselectcolor {
    background: #ff5455;
    border: none;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 6px;
    cursor: pointer;
}

.setselectcolor:focus {
    box-shadow: none;
}

@media (max-width: 575px) {
    .setmenubar {
        display: block;
    }

    .headerline {
        font-size: 18px;
        color: black;
        padding: 15px 15px 15px;
    }

    .datessetfont {
        text-align: center;
        padding-bottom: 10px;
    }
}

.setscreenheight {
    height: 100dvh;
    overflow: auto;
    background-color: #ffbaba;
}

@media (min-width: 1161px) and (max-width:1199px) {
    .calendar-table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border: none;
        font-size: 16px;
    }
}

@media (min-width: 1122px) and (max-width:1160px) {
    .calendar-table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border: none;
        font-size: 15px;
    }
}

@media (min-width: 1083px) and (max-width:1159px) {
    .calendar-table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border: none;
        font-size: 14px;
    }
}

@media (min-width: 1043px) and (max-width:1082px) {
    .calendar-table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border: none;
        font-size: 13px;
    }
}

@media (min-width: 1007px) and (max-width:1042px) {
    .calendar-table td {
        font-size: 15px;
    }

    .calendar-table th {
        padding: 0;
        font-size: 14px;
    }

    .manageinputwidth {
        padding: 0 20px;
    }
}

@media (min-width: 971px) and (max-width:1006px) {
    .calendar-table td {
        font-size: 15px;
    }

    .calendar-table th {
        padding: 0;
        font-size: 14px;
    }

    .manageinputwidth {
        padding: 0 20px;
    }
}

@media (min-width: 936px) and (max-width: 970px) {
    .calendar-table td {
        font-size: 15px;
    }

    .calendar-table th {
        padding: 0;
        font-size: 14px;
    }

    .manageinputwidth {
        padding: 0 20px;
    }
}

@media (min-width: 910px) and (max-width: 935px) {
    .calendar-table td {
        font-size: 15px;
    }

    .calendar-table th {
        padding: 0;
        font-size: 14px;
    }

    .manageinputwidth {
        padding: 0 20px;
    }
}

@media (min-width: 892px) and (max-width: 909px) {
    .calendar-table td {
        font-size: 15px;
    }

    .calendar-table th {
        padding: 0;
        font-size: 14px;
    }

    .manageinputwidth {
        padding: 0 20px;
    }
}

@media (min-width: 860px) and (max-width: 891px) {
    .calendar-table td {
        font-size: 15px;
    }

    .calendar-table th {
        padding: 0;
        font-size: 14px;
    }

    .manageinputwidth {
        padding: 0 20px;
    }
}

@media (min-width: 837px) and (max-width: 859px) {
    .calendar-table td {
        font-size: 14px;
    }

    .calendar-table th {
        padding: 0;
        font-size: 13px;
    }

    .manageinputwidth {
        padding: 0 20px;
    }
}

@media (min-width: 821px) and (max-width: 836px) {
    .calendar-table td {
        font-size: 15px;
    }

    .calendar-table th {
        padding: 0;
        font-size: 11px;
    }

    .manageinputwidth {
        padding: 0 10px;
    }
}

@media (min-width: 801px) and (max-width: 820px) {
    .calendar-table td {
        font-size: 14px;
    }

    .calendar-table th {
        padding: 0;
        font-size: 9px;
    }

    .manageinputwidth {
        padding: 0 10px;
    }
}

@media (min-width: 767px) and (max-width: 800px) {
    .calendar-table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border: none;
        font-size: 14px;
    }

    .manageinputwidth {
        padding: 0 20px !important;
    }
}

@media (min-width: 580px) and (max-width: 766px) {
    .calendar-table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border: none;
        font-size: 15px;
    }
    .calendar-table td {
        font-size: 17px;
        padding: 0;
    }
    .calendar-table th {
        padding: 0 0 10px 0;
    }

}

@media (min-width: 529px) and (max-width: 579px) {
    .calendar-table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border: none;
        font-size: 13px;
    }
    .calendar-table td {
        font-size: 17px;
        padding: 0;
    }
    .calendar-table th {
        padding: 0 0 10px 0;
        font-size: 13px;
    }
}

@media (min-width: 504px) and (max-width: 528px) {
    .calendar-table td {
        font-size: 17px;
        padding: 0;
    }

    .calendar-table th {
        padding: 0 0 10px 0;
        font-size: 13px;
    }

}

@media (min-width: 477px) and (max-width: 503px) {
    .calendar-table td {
        font-size: 16px;
        padding: 0;
    }

    .calendar-table th {
        padding: 0 0 10px 0;
        font-size: 12px;
    }

}

@media (min-width: 453px) and (max-width: 476px) {
    .calendar-table td {
        font-size: 15px;
        padding: 0;
    }

    .calendar-table th {
        padding: 0 0 10px 0;
        font-size: 11px;
    }

}

@media (min-width: 430px) and (max-width: 452px) {
    .calendar-table td {
        font-size: 15px;
        padding: 0;
    }

    .calendar-table th {
        padding: 0 0 10px 0;
        font-size: 10px;
    }

}

@media (max-width: 429px) and (min-width: 365px) {
    .calendar-table td {
        font-size: 15px;
        padding: 0;
    }

    .calendar-table th {
        padding: 0 0 10px 0;
        font-size: 12px;
    }
}

@media (max-width: 364px) {
    .calendar-table td {
        font-size: 15px;
        padding: 0;
    }

    .calendar-table th {
        padding: 0 0 10px 0;
        font-size: 10px;
    }
}

.sidebardetailsfontsmall {
    font-size: 12px;
    color: #727272;
    padding: 7px 0;
    font-weight: 700;
}

.sidebardetailsfirstsizeset {
    font-size: 16px;
    color: #727272;
    padding: 7px 0;
    font-weight: 700;
}

.sidebardetailssecsizeset {
    color: #ffbbbb;
    font-weight: 700;
    font-size: 16px;
    padding: 7px 0;

}

.calenderdatesset {
    color: #7ece7e;
    font-weight: 700;
    font-size: 16px;
    padding: 7px 0;

}

.calenderdatasetanother {
    color: #ff8080;
    font-weight: 700;
    font-size: 16px;
    padding: 7px 0;

}

.SecDa {
    color: #727272;
    font-size: 16px;
    font-weight: 700;
    padding: 7px 0;
}

.SecDaH {
    color: #ff8080;
    font-size: 16px;
    font-weight: 700;
    padding: 7px 0;
}

.FM {
    border-radius: 7px;
    width: 13px;
    height: 13px;
    background: #ffffcc;
    border: solid #CCCCCC 1px;
    margin-left: 5px;
}

.NM {
    border-radius: 7px;
    width: 13px;
    height: 13px;
    background: #666666 !important;
    border: 1px solid white;
    margin-left: 5px;
}

.weekdayclass {
    color: #cca6f2;
    font-size: 16px;
    font-weight: 700;
}

.MCPubHol {
    color: #ff8080;
    font-size: 16px;
    font-weight: 700;
    padding: 7px 0;
}

.anchorFootMC {
    color: #cca6f2;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 0;
    text-decoration: none;
}

@media (max-width: 392px) {
    .setyearresponsive {
        padding: 0px !important;
    }

    .setarrowres {
        padding: 0px !important;
    }
}

@media (max-width: 575px) {
    .datessetfont {
        text-align: center;
        padding-bottom: 0px !important;
    }

    .setselectmenu {
        padding-bottom: 15px;
    }

    .setmenubar {
        padding: 30px 15px 0px !important;
    }
}

.phaseofmoon {
    background: #ff5455;
    margin: 7px 40px;
    gap: 10px
}

.moonsetcenter {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
}

.holidaytitle {
    font-size: 18px;
    font-weight: 700;
}

.setholidayscroll {
    padding: 0 40px;
}

/* .setholidayscroll::-webkit-scrollbar {
    display: none;
  } */
.holidayname {
    font-size: 16px;
    color: #727272;
    font-weight: 700;
}

.datecolorset {
    font-size: 16px;
    color: #ff5455;
    font-weight: 700;
}

.holidaydataspace {
    padding: 5px 0;
}

.moongap {
    gap: 10px
}

@media (min-width: 768px) {
    .setholidayscroll {
        overflow-y: auto;
        min-height: 50px;
        max-height: 250px;
        scrollbar-width: thin;
        scrollbar-color: #ff5455 transparent;
    }

    .setholidayscroll::-webkit-scrollbar {
        width: 1px;
    }

    .setholidayscroll::-webkit-scrollbar-thumb {
        background-color: #ff5455;
        border-radius: 10px;
    }

    .setholidayscroll::-webkit-scrollbar-track {
        background-color: transparent;
    }

}

.setmonthmooncolor {
    color: white;
}

/* .managesdetailscroll{
    overflow-y: auto;
    min-height: calc(100vh - 600px);
    max-height: calc(100vh - 600px);
} */

@media (min-width: 2561px) {
    .managespacedetails {
        padding: 0 40px;
        font-weight: 200;
        overflow: auto;
        min-height: 50px;
        max-height: 380px;
        scrollbar-width: thin;
        scrollbar-color: #ff5455 transparent;
    }
}

.wasocalender {
    background: #ff5455;
    padding: 5px 10px;
    font-size: 16px;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.setwasocalender {
    position: absolute;
    bottom: 20px;
}

.wasosetflex {
    display: flex;
    justify-content: center;
}

@media (max-width: 767px) {
    .setwasocalender {
        position: unset;
        margin-bottom: 20px;
    }
}

@media (min-width: 927px) and (max-width:992px) {
    .wasocalender {
        background: #ff5455;
        padding: 5px 10px;
        font-size: 10px;
        color: white;
        font-weight: 700;
        cursor: pointer;
    }
}

@media (min-width: 878px) and (max-width: 926px) {
    .wasocalender {
        background: #ff5455;
        padding: 5px 10px;
        font-size: 9px;
        color: white;
        font-weight: 700;
        cursor: pointer;
    }
}

@media (min-width: 801px) and (max-width: 877px) {
    .wasocalender {
        background: #ff5455;
        padding: 5px 10px;
        font-size: 7px;
        color: white;
        font-weight: 700;
        cursor: pointer;
    }
}

@media (min-width: 768px) and (max-width: 800px) {
    .wasocalender {
        background: #ff5455;
        padding: 5px 10px;
        font-size: 11px;
        color: white;
        font-weight: 700;
        cursor: pointer;
    }
}

.conditionalset {
    display: flex;
    overflow-x: scroll;
}

.conditionalset::-webkit-scrollbar {
    display: none;
}

@media (max-width: 400px) and (min-width: 320px) {
    .monthcolorset{
        font-size: 18px;
    }
    .manageinputwidth .form-control {
        font-size: 18px !important; 
    }
}
@media (max-width: 338px) {
    .monthcolorset{
        font-size: 16px;
    }
    .manageinputwidth .form-control {
        font-size: 16px;
    }
}

.moonresponsivespace{
    gap:5px
}