.zodicsignback {
    background-color: #ffeded;
    padding: 30px;
    height: 100dvh;
    overflow-y: scroll;
}
.signtitle{
    font-weight: 700;
}
.zodicsignscolor{
    color: #ff5455;;
}
.zodictitledetails{
    color: #888;
    padding: 15px 0;
}
.imgtitlefirst{
    padding: .4em;
    font-size: .9em;
    background: rgba(0, 0, 0, .05);
    font-style: italic;
    display: inline-flex;
    justify-content: center;
    width: 50%;
}
.firstsecul{
    color: #888;
    padding: 15px 0 0 0
}

@media (min-width: 801px) and (max-width: 1023px) {
    .zodiacimgwidth{
        width:90%
    }
}

@media (max-width: 768px) {
    .zodiacimgwidth{
        width:80%
    }
    .imgtitlefirst{
        width: 80%;
    }
}