/*  Calander */
.calenderfirstsection {
  background: #ff5455;
  height: 100%;
}

.calendertreehouse {
  font-size: 19px;
  color: white;
  text-align: center;
  font-weight: 700;
  margin-top: 20px;
}

.mountaintree {
  width: 145px;
  height: 145px;
  /* border-radius: 15px; */
}

.settreecenter {
  padding: 50px 0px;
}

.calenderwhite {
  color: white;
}

.calendarword {
  font-size: 19px;
  color: white;
  font-weight: 700;
  padding-left: 20px;
}

.todolist {
  font-size: 19px;
  color: white;
  font-weight: 700;
  padding-left: 15px;
}

.cursorpointer {
  cursor: pointer;
}

.active-item {
  background-color: #ffbaba;
  color: #ff5455;
}

.active-item .calendarword,
.active-item .calenderwhite,
.active-item .todolist {
  color: #ff5455;
}

.versioncheck {
  color: white;
  font-size: 13px;
  font-weight: 200;
  text-align: center;
}

.setversion {
  position: absolute;
  bottom: 20px;
  margin: 0px auto;
  width: 100%;
}

.sidebarsetheight {
  height: 100dvh;
}

.menuiconset {
  left: 14px;
  top: 14px;
}

.setmenuicon{
  padding: 10px 8px;
  gap: 20px;
  background-color: #ffbaba;
}

.calenderwordother{
  font-size: 25px;
  font-weight: 700;
}

.sunset{
  width: 25px;
  height: 25px;
}

.sunset{
  background-image: url("./Images/sunsetwhite.png");
  width: 25px;
  height: 25px;
  background-size: contain;
}

.active-item .sunset {
  background-image: url("./Images/sunset.png");
  background-size: contain;
}
.mainwidthset{
  width: calc(100% - 250px);
}

@media (max-width: 800px) {
  .mainwidthset{
    width: 100%;
  }
}

.spinercolorset{
  color: #ff5455;
}

.playstore{
  width: 100px;
  margin-right: 10px;
  cursor: pointer;
}
.applestore{
  width: 100px;
  cursor: pointer;
}