.holidayheightset {
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.holidays {
    font-size: 25px;
    font-weight: 700;
}

.holidaysbgcolorset {
    background-color: #ffeded;
    padding: 20px;
    height: 100dvh;
    overflow-y: scroll;
}

.holiday-table th,
.holiday-table td {
    vertical-align: middle;
    background-color: transparent;
    border-bottom: 1px solid #d6d6d6;
    white-space: nowrap;
}

/* @media (max-width: 800px) {
    .holiday-table th,
.holiday-table td {
    white-space: nowrap;
}
} */

.holiday-table {
    background-color: transparent;
    margin-top: 20px;
    border-collapse: collapse;
    border: 0;
}

.theadholiday {
    border-bottom: 1px solid #d6d6d6;
}

.holidaydatecolor {
    color: black !important;
    font-weight: 500;
}

.holidaydaycolor {
    color: #888 !important;
}

.holidaynamecolor {
    color: #ff5455 !important;
}

@media (max-width: 768px) {

    .holiday-table th,
    .holiday-table td {
        font-size: 14px;
    }

    .holidays {
        text-align: center;
        margin-top: 15px;
    }

    .holidaytitleflex {
        display: block !important;
    }

    .setselectmenuholiday {
        padding-top: 15px;
    }
}

.holidaytitleflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.setselectcolorholiday {
    background: #ff5455;
    border: none;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 6px;
    cursor: pointer;
}

.setselectcolorholiday:focus {
    box-shadow: none;
}

.setselectmenuholiday {
    gap: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.holidaycomments {
  width: 150px; 
  word-wrap: break-word; 
}